.App {
  background-color: #f0f0f0;
  margin: 0;
  /*background-color: #246065;*/

}

.compactDiv{
  margin: 0 auto;
  width: 70%;
}

.textAlignLeft{
  text-align: left;
}
.textAlignCenter{
  text-align: center;
}

/*HEADER*/
.headerClass{
   background-color: lightgray;
   width: 100%;
   padding: 0px 0;
 }

.mobile-top-header{
  background-color: lightgray;
  /*min-height: 5vh;*/
  width: 100%;

}

.vertical-header{
  text-align: left;
  background-color: darkslategrey;
  max-width: 300px;
}

/*MAIN LAYOUT*/
.grid{
  width: 100%;
  margin: 0 !important;
}

.mainContent{
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}


/*UTIL*/
.borderBlack{
  border:  solid 1.5px !important;
}


.customPadding{
  margin:0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.removePadding{
  margin:0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.removeTopPadding{
  padding-top: 0 !important;
}

.removeLeftRightPadding{
  margin:0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.removeBottomPadding{
  margin:0;
  padding-bottom: 0 !important;
}

.removeRightPadding{
  padding-right: 0 !important;

}

.removeLeftPadding{
  padding-left: 0 !important;
}

.placeholder{
  min-height: 95vh;
}

.float-right{
  text-align: right;
}

.float-left{
  text-align: left;
}


/*SEGMENT*/
.background{
  background-color: rgba(73, 221, 226, 0.2) !important;
}

/*FOOTER*/
.footer-secondary{
  background-color: white;
  height: 40px;
  width: 100%;
}

.bottom-fixed{
  position: fixed;
  bottom: 0;
}

.footer{
  margin-top: 300px;
  position: relative;
  bottom: 30px;
  width: 100%;
  padding: 10px;
  border-top: 1px;
  /*box-shadow: 0px 12px 20px #888888;*/
  /*background-color: white;*/
  background-image: linear-gradient(to top, #ffffff, #ffffff);


}

.bottomPadding{
  padding-bottom: 30px !important;
}

.map{
  height: 200px;
  overflow: auto;
}
/*HOME PAGE*/


